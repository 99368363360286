/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";

function Content() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tournamentId,setTournamentId] = useState("")

  const getVideos = async () => {
    const pathname = window.location.pathname.split("/");
    setTournamentId(pathname[pathname.length - 1]);
    var config = {
      method: "post",
      url: `https://o50zw4lkpj.execute-api.us-east-1.amazonaws.com/prod/tvDotDe/get-videos/${
        pathname[pathname.length - 1]
      }`,
      headers: {
        "x-api-key": "hSolVipPRd5spJPjTYS1S3D0gZn9nh3B6igy23gA",
      },
    };

    await axios(config)
      .then(function (response) {
        console.log(response.data);
        response.data.translatedResult.map((e) =>
          setVideos((pre) => [...pre, e])
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getVideos();
    setTimeout(setLoading, 1500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-light container">
      <h4 class="d-flex justify-content-center m-3">Related Videos</h4>
      {videos.map((e) => {
        return (
          <a
            href={`https://tvdotde.clips.footylight.com/rss/${e.content_id}/de/${tournamentId}`}
            style={{ textDecoration: "none", color: "inherit" }}
            className="row py-2 video-card">
            <div className="col-6">
              <img
                src={e.thumbnail}
                alt="Thumbnail"
                style={{ objectFit: "cover", height: "100px", width: "180px" }}
              />
            </div>
            <div className="col-6">
              <div
                style={{
                  height: "3em",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  lineClamp: 2,
                  fontWeight: 500,
                }}
              >
                {e.title}...
              </div>
              <p
                className="mt-2"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: 300,
                  opacity: 0.6,
                }}
              >
                By {e.creator === "Opta" ? "FootyLight" : e.creator}
              </p>
            </div>
          </a>
        );
      })}
      {loading && (
        <div class="spinner-border text-success d-flex justify-content-center" role="status">
          <span class="visually-hidden">Loading</span>
        </div>
      )}
    </div>
  );
}

export default Content;
