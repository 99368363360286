import Tournaments from "./components/Tournaments";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Content from "./components/Content";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {" "}
          <Route path="/" element={<Tournaments />} />
          <Route path="/tournament" element={<Tournaments />} />
          <Route path="/tournament/:id" element={<Content />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
