import React from "react";
import { Link } from "react-router-dom";

const TournamentList = [
  { id: "1", title: "Fifa World Cup", logo: "/world-cup-2022.jpg" },
  // {
  //   id: "2",
  //   title: "Euro Cups: UEFA Nations League",
  //   logo: "/uefa-nations-league.svg",
  // },
  // { id: "3", title: "UEFA Champions League", logo: "/champions-league.svg" },
  { id: "4", title: "Bundesliga", logo: "/bundesliga.svg" },
  // { id: "5", title: "English Premier League", logo: "/premier-league.svg" },
  // { id: "6", title: "La Liga", logo: "/LaLiga.svg" },
  // { id: "7", title: "Serie A", logo: "/serie-A.svg" },
  // { id: "8", title: "Ligue 1", logo: "L1.png" },
];
const Tournaments = () => {
  return (
    <div class="container">
      <h4 class="d-flex justify-content-center m-3">Tournaments</h4>
      {TournamentList.map((tournament) => {
        return (
          <div class="border row align-items-center">
            <div class="col-1 p-2">
              <Link
                to={"/tournament/" + tournament.id}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "contain",
                    borderRadius: "5px",
                  }}
                  src={tournament.logo}
                  alt=""
                />
              </Link>
            </div>
            <div class="col-10 p-2">
              <Link
                to={"/tournament/" + tournament.id}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <span class="ml-4"> {tournament.title}</span>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Tournaments;
